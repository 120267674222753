import { render, staticRenderFns } from "./TableProduct.vue?vue&type=template&id=5f7c8bbf&scoped=true"
import script from "./TableProduct.vue?vue&type=script&lang=js"
export * from "./TableProduct.vue?vue&type=script&lang=js"
import style0 from "./TableProduct.vue?vue&type=style&index=0&id=5f7c8bbf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f7c8bbf",
  null
  
)

export default component.exports